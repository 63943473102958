import React from 'react'

import Layout from '../components/layout'
import Seo from '../components/seo'

import ContentWithImage from '../components/sections/contentWithImage'

const About = () => (
	<Layout>
		<Seo title='О нас' />

		<ContentWithImage
			uptitle='О нас'
			title='EnotWeb'
			image='../../images/raccoon-000-unsplash-1920.jpg'
			imagealt='Веб-студия EnotWeb'
			caption='Фотограф Joshua J. Cotten'
			leadtext='Пока я придумываю текст, вот тебе главные тезисы: работает для тебя один человек и его группа поддержки, делаем до 2-х сайтов в месяц, работаем только за деньги. Создаем сайты на WordPress, находим лучшие решения, делимся опытом в разработке и развитии сайтов.'
		>
			<p>Основной текст напишу здесь немного позже...</p>
		</ContentWithImage>

	</Layout>
)

export default About